import { CriteriaModel, ExternalAuthKeyRequest, ExternalAuthKeyResponse, PurchasePackageRequest, PurchasePackageResponse, UserResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const purchasePackageGetListPath = '/purchasepackage/getlist';
const purchasePackageSavePath = '/purchasepackage/save';
const purchasePackageDeletePath = '/purchasepackage/delete';
const purchasePackageGetPath = '/purchasepackage/get';

export const purchasePackageGetList = async (data: CriteriaModel): Promise<PurchasePackageResponse> => {
  try {
    const response = await axiosInstance.post(purchasePackageGetListPath, data);
    return response.data;
  } catch (error) {
    console.error('purchasePackageGetList failed:', error);
    throw error;
  }
};

export const purchasePackageSave = async (request: PurchasePackageRequest): Promise<PurchasePackageResponse> => {
  try {
    const response = await axiosInstance.post(purchasePackageSavePath, request);
    return response.data;
  } catch (error) {
    console.error('purchasePackageSave failed:', error);
    throw error;
  }
};

export const purchasePackageDelete = async (id: string): Promise<PurchasePackageResponse> => {
  try {
    let url = purchasePackageDeletePath + '?id=' + id;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeyDelete failed:', error);
    throw error;
  }
};

export const purchasePackageGet = async (id: string): Promise<PurchasePackageResponse> => {
  try {
    let url = purchasePackageGetPath + '?id=' + id;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('purchasePackageGet failed:', error);
    throw error;
  }
};
import { CriteriaModel, BlockUserRequest, BlockUserResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const blockUserGetListPath = '/blockUser/getlist';
const blockUserSavePath = '/blockUser/save';
const blockUserDeletePath = '/blockUser/delete';

export const blockUserGetList = async (data: CriteriaModel): Promise<BlockUserResponse> => {
  try {
    const response = await axiosInstance.post(blockUserGetListPath, data);
    return response.data;
  } catch (error) {
    console.error('blockUserGetList failed:', error);
    throw error;
  }
};

export const blockUserSave = async (request: BlockUserRequest): Promise<BlockUserResponse> => {
  try {
    const response = await axiosInstance.post(blockUserSavePath, request);
    return response.data;
  } catch (error) {
    console.error('blockUserSave failed:', error);
    throw error;
  }
};

export const blockUserDelete = async (id: string): Promise<BlockUserResponse> => {
  try {
    let url = blockUserDeletePath + '?id=' + id;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error('blockUserDelete failed:', error);
    throw error;
  }
};
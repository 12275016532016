import React, { useState } from 'react';
import './DynamicFormPopupStyle.css';
import { DynamicFormPopupProps } from '../../friday/models/authModel';

const DynamicValidationPopup: React.FC<DynamicFormPopupProps> = ({
  isOpen,
  title,
  bodyText,
  onSaveEvent,
  onCancel,
}) => {

  const handleSave = () => {
    onSaveEvent();
  };

  if (!isOpen) return null;

  return (
    <div className="dynamic-form-popup-overlay">
      <div className="dynamic-form-popup-container dynamic-form-popup">
        <h2 className="dynamic-form-title">{title}</h2>
        <p style={{ color: 'black' }}>{bodyText}</p>
        <div className="button-group">
          <button type="button" className="save-button" style={{ backgroundColor: 'red' }} onClick={handleSave}>
            Delete
          </button>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicValidationPopup;

import { CriteriaModel, ExternalAuthKeyRequest, ExternalAuthKeyResponse, UserResponse } from "../models/authModel";
import axiosInstance from "./axiosConfig";

const externalAuthKeyGetListPath = '/externalauthkey/getlist';
const externalAuthKeySavePath = '/externalauthkey/save';
const externalAuthKeyDeletePath = '/externalauthkey/delete';
const externalAuthKeyGetPath = '/externalauthkey/get';

export const externalAuthKeyGetList = async (data: CriteriaModel): Promise<ExternalAuthKeyResponse> => {
  try {
    const response = await axiosInstance.post(externalAuthKeyGetListPath, data);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeyGetList failed:', error);
    throw error;
  }
};

export const externalAuthKeySave = async (request: ExternalAuthKeyRequest): Promise<ExternalAuthKeyResponse> => {
  try {
    const response = await axiosInstance.post(externalAuthKeySavePath, request);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeySave failed:', error);
    throw error;
  }
};

export const externalAuthKeyDelete = async (id: string): Promise<ExternalAuthKeyResponse> => {
  try {
    let url = externalAuthKeyDeletePath + '?id=' + id;
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeySave failed:', error);
    throw error;
  }
};

export const externalAuthKeyGet = async (id: string): Promise<ExternalAuthKeyResponse> => {
  try {
    let url = externalAuthKeyGetPath + '?id=' + id;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('externalAuthKeyGet failed:', error);
    throw error;
  }
};
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState } from 'material-react-table';
import { CriteriaModel } from '../../friday/models/authModel';
import { useGlobalContext } from '../../friday/utils/globalContext';
import DynamicValidationPopup from '../../friday/components/DynamicValidationPopup';

interface BaseListResponse<T> {
  isSuccess: boolean;
  messages?: MessageModel[];
  data?: T[];
  totalCount?: number;
}

interface MessageModel {
  type: string;
  text: string;
}

export interface GenericTableHandle {
  refreshTable: () => void;
}

type GenericTableProps<T, R> = {
  getServiceCall: (params: CriteriaModel) => Promise<R>; // Generic return type
  columns: MRT_ColumnDef<T>[]; // Columns definition
  deleteTitle?: string;
  deleteBodyText?: string;
  deleteServiceFunction?: any;
  isDeletePopup?: boolean;
};

const GenericTable = forwardRef<GenericTableHandle, GenericTableProps<any, any>>(
  (props, ref) => {
  const { refreshMainTable } = useGlobalContext()
  const { getServiceCall, columns, deleteTitle, deleteBodyText, deleteServiceFunction, isDeletePopup } = props;

  const [deleteId, setDeleteId] = useState<string|undefined>(undefined);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [refreshTable, setRefreshTable] = useState<() => void>(() => () => {}); 

  // Table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 0,
  });

  useEffect(() => {
    if (pagination?.pageIndex >= 0) {
      fetchData();
    }
  }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, refreshMainTable]);

  useEffect(() => {
    if (isDeletePopup && deleteServiceFunction) {
      if (columns.filter(x => x.accessorKey === 'delete').length === 0) {
        let newDeleteColumn = {
          accessorKey: 'delete',
          header: 'Actions',
          Cell: ({ row }) => (
            <div key={row.original.id} className="grid-actions">
              <button onClick={() => handleOpenDeletePopUp(row.original.id)}>Delete</button>
            </div>
          ),
        }
        columns.push(newDeleteColumn);
      }
    }
  }, [columns, isDeletePopup])


const fetchData = async () => {
      setIsLoading(data.length === 0);
      setIsRefetching(data.length > 0);

      const params: CriteriaModel = {
        page: pagination?.pageIndex ?? 0,
        pageSize: pagination.pageSize,
      };

      try {
        console.log("fetching...")
        const response = await getServiceCall(params);

        // Extract 'data' and 'totalCount' dynamically from the response type
        const responseData = (response as any)?.data ?? [];
        const responseTotalCount = (response as any)?.totalCount ?? 0;

        setData(responseData);
        setRowCount(responseTotalCount);
        setIsError(false);
        setErrorMessage(null);
      } catch (error) {
        setIsError(true);
        setErrorMessage('An unexpected error occurred.');
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsRefetching(false);
      }
    };

  const handlePaginationChange = (newPagination: MRT_PaginationState) => {
    const { pageIndex, pageSize } = newPagination;
    // Ensure pageIndex is valid, default to 0 if NaN
    const validatedPageIndex = isNaN(pageIndex) ? 0 : pageIndex;
    const validatedPageSize = pageSize > 0 ? pageSize : 0; // Ensure pageSize is greater than 0
  
    // Update pagination state with validated values
    if (!(pagination.pageIndex == validatedPageIndex && pagination.pageSize == validatedPageSize)) {
      setPagination({
        pageIndex: validatedPageIndex,
        pageSize: validatedPageSize,
      }); 
    }
  };

  const handleDeletePopUpSave = async () => {
    // todo call delete service pass id here
    const response = await deleteServiceFunction?.(deleteId);
    if (response?.isSuccess) {
      setIsPopupOpen(false);
      fetchData();
    }
  }

  const handleOpenDeletePopUp = (id: string) => {
    setDeleteId(id);
    setIsPopupOpen(true);
  }

  const handleDeletePopUpCancel = () => {
    setDeleteId(undefined);
    setIsPopupOpen(false);
  }

  return (<div>
    <DynamicValidationPopup
      isOpen={isPopupOpen}
      title={deleteTitle ?? "Delete"}
      bodyText={deleteBodyText ?? "Are you sure you want to delete?"}
      onSaveEvent={handleDeletePopUpSave}
      onCancel={handleDeletePopUpCancel}
    />
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection={true}
      manualFiltering={true}
      manualPagination={true}
      manualSorting={true}
      muiToolbarAlertBannerProps={isError ? { color: 'error', children: errorMessage || 'Error loading data' } : undefined}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={handlePaginationChange}
      onSortingChange={setSorting}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
    />
  </div>);
}) as <T extends object, R>(
  props: GenericTableProps<T, R> & { ref?: React.Ref<GenericTableHandle> }
) => JSX.Element;

export default GenericTable;

import { userGetList } from '../../friday/services/userService';
import { AppModel, UserModel, UserResponse } from '../../friday/models/authModel';
import GenericTable, { GenericTableHandle } from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useRef } from 'react';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const appColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'firstName',
    header: 'FirstName',
  },
  {
    accessorKey: 'lastName',
    header: 'LastName',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export default function UserTable () {

  return (
    <ThemeProvider theme={theme}>
      <GenericTable<UserModel, UserResponse>
        getServiceCall={userGetList} // No changes here!
        columns={appColumns}
      />
    </ThemeProvider>
  );
};

import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from '../../components/card/Card.js';
import DynamicFormPopup from "./DynamicFormPopup";
import { BlockUserRequest, FormField } from "../../friday/models/authModel";
import { useGlobalContext } from "../../friday/utils/globalContext";
import { blockUserSave } from "../../friday/services/blockUserService";
import BlockUserTable from "./BlockUserTable";

const formFields: FormField[] = [
  { name: 'userId', label: 'UserId', type: 'text' },
  { name: 'iPAdress', label: 'IPAdress', type: 'text' },
  { name: 'reason', label: 'Reason', type: 'text' }
];

export default function BlockUser() {
  const { setLoading, refreshMainTable, setRefreshMainTable } = useGlobalContext()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const onSaveBlockUser = async (values: any) => {
    try {
      console.log("onSaveBlockUser:", values)
      const request: BlockUserRequest = { data: 
        [{
          id: null,
          appId: null,
          createDate: null,
          updateDate: null,
          userId: values?.userId == "" ? null : values?.userId,
          iPAdress: values?.iPAdress == "" ? null : values?.iPAdress,
          reason: values?.reason,
          isPermanent: true,
          blockedUntil: null
        }] 
      };
      setLoading(true);
      const response = await blockUserSave(request);
      if (response?.isSuccess) {
        refreshData();
        setIsPopupOpen(false);
      }
    } catch (error) {
      alert(`onSaveBlockUser failed: ${error.message || 'Unknown error occurred.'}`);
    }
    finally {
      setLoading(false);
    }
  };

  const onClickAddNew = () => {
    setIsPopupOpen(true)
  }

  const handleSave = (values: { [key: string]: any }) => {
    onSaveBlockUser(values)
  };

  const handleCancel = () => {
    setIsPopupOpen(false);
  };

  const refreshData = () => {
    setRefreshMainTable(!refreshMainTable)
  }

  return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  <div>
    <DynamicFormPopup
      fields={formFields}  // error line
      isOpen={isPopupOpen}
      title="Yeni Block User Ekle"
      onSave={handleSave}
      onCancel={handleCancel}
    />
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex direction={"row"}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Block Users
        </Text>
        <div onClick={() => onClickAddNew()} style={{ marginLeft: "auto", cursor: "pointer"}}>
          <Text
            border={"1px solid"}
            borderRadius={"20px"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            color={textColorPrimary}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            + Yeni Ekle
          </Text>
        </div>
      </Flex>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Uygulamadaki kullanıcıyı bloklamak için UserId sini veya IPAdress ini girebilirsiniz.
      </Text>
    </Card>
    <BlockUserTable />
  </div>
  </Box>);
}

import { userGetList } from '../../friday/services/userService';
import { AppModel, AuthTypeEnumNames, CriteriaModel, DeviceTypeEnumNames, ExternalAuthKeyModel, ExternalAuthKeyResponse, UserModel } from '../../friday/models/authModel';
import GenericTable, { GenericTableHandle } from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { externalAuthKeyDelete, externalAuthKeyGetList } from '../../friday/services/externalAuthKeyService';
import { useRef } from 'react';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const gridColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'authTypeValue',
    header: 'AuthType',
  },
  {
    accessorKey: 'deviceTypeValue',
    header: 'DeviceType',
  },
  {
    accessorKey: 'clientId',
    header: 'ClientId',
  },
  {
    accessorKey: 'secret',
    header: 'Secret',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export const externalAuthKeyGetListEvent = async (data: CriteriaModel) => {
  const response = await externalAuthKeyGetList(data); 
  response.data.forEach(item => {
    item.authTypeValue = AuthTypeEnumNames[item.authType];
    item.deviceTypeValue = DeviceTypeEnumNames[item.deviceType];
  });
  return response;
};

export default function ExternalAuthTable () {

  return (
    <ThemeProvider theme={theme}>
    <GenericTable<ExternalAuthKeyModel, ExternalAuthKeyResponse>
      getServiceCall={externalAuthKeyGetListEvent} // No changes here!
      columns={gridColumns}
      isDeletePopup={true}
      deleteServiceFunction={externalAuthKeyDelete}
    />
    </ThemeProvider>
  );
};

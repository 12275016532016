import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from '../../components/card/Card.js';
import DynamicFormPopup from "./DynamicFormPopup";
import { FormField, PurchasePackageRequest } from "../../friday/models/authModel";
import { useGlobalContext } from "../../friday/utils/globalContext";
import { purchasePackageSave } from "../../friday/services/purchasePackageService";
import PurchasePackageTable from "./PurchasePackageTable";

const formFields: FormField[] = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'credits', label: 'Credits', type: 'number' },
  { name: 'isForAdd', label: 'IsForAdd', type: 'text' }
];

export default function PurchasePackage() {
  const { setLoading, refreshMainTable, setRefreshMainTable } = useGlobalContext()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const onSavePurchasePackage = async (values: any) => {
    try {
      const request: PurchasePackageRequest = { data: 
        [{id: null,
          appId: null,
          createDate: null,
          updateDate: null,
          credits: Number(values?.credits), 
          name: values?.name,
          isForAdd: values?.isForAdd == "true" ? true : false
        }] 
      };
      setLoading(true);
      const response = await purchasePackageSave(request);
      if (response?.isSuccess) {
        refreshData();
        setIsPopupOpen(false);
      }
    } catch (error) {
      alert(`onSavePurchasePackage failed: ${error.message || 'Unknown error occurred.'}`);
    }
    finally {
      setLoading(false);
    }
  };

  const onClickAddNew = () => {
    setIsPopupOpen(true)
  }

  const handleSave = (values: { [key: string]: any }) => {
    onSavePurchasePackage(values)
  };

  const handleCancel = () => {
    setIsPopupOpen(false);
  };

  const refreshData = () => {
    setRefreshMainTable(!refreshMainTable);
  }

  return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  <div>
    <DynamicFormPopup
      fields={formFields}  // error line
      isOpen={isPopupOpen}
      title="Yeni Purchase Package Ekle"
      onSave={handleSave}
      onCancel={handleCancel}
    />
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex direction={"row"}>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Purchase Packages
        </Text>
        <div onClick={() => onClickAddNew()} style={{ marginLeft: "auto", cursor: "pointer"}}>
          <Text
            border={"1px solid"}
            borderRadius={"20px"}
            paddingLeft={"12px"}
            paddingRight={"12px"}
            color={textColorPrimary}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            fontWeight='bold'
            fontSize='2xl'
            mt='10px'
            mb='4px'>
            + Yeni Ekle
          </Text>
        </div>
      </Flex>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Satın alınmak için RevenueCat te tanımlanan paket isimlerini ekleyebilirsiniz. ex: 'premium_credit_10'
      </Text>
    </Card>
    <PurchasePackageTable />
  </div>
  </Box>);
}

// GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { GetLocalStorageData } from './localStorageHelper';
import { colorMode } from './localStorageHelper';
import { AppModel } from '../models/authModel';

// Define the context type
interface GlobalContextType {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  appListData: AppModel[];
  setAppListData: React.Dispatch<React.SetStateAction<AppModel[]>>;
  selectedApp: AppModel | null;
  setSelectedApp: React.Dispatch<React.SetStateAction<AppModel | null>>;
  isLightColor: boolean;
  setIsLightColor: React.Dispatch<React.SetStateAction<boolean>>;
  refreshMainTable: boolean;
  setRefreshMainTable: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with a default value
const GlobalContext = createContext<GlobalContextType>({
  loading: false,
  setLoading: () => {},
  userData: null,
  setUserData: () => {},
  appListData: [],
  setAppListData: () => {},
  selectedApp: null,
  setSelectedApp: () => {},
  isLightColor: false,
  setIsLightColor: () => {},
  refreshMainTable: false,
  setRefreshMainTable: () => {}
});

// Custom hook for consuming the context easily
export const useGlobalContext = () => useContext(GlobalContext);

// Context provider component
export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [refreshMainTable, setRefreshMainTable] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedApp, setSelectedApp] = useState<AppModel>(null);
  const [appListData, setAppListData] = useState<AppModel[]>([]);
  const [isLightColor, setIsLightColor] = useState((GetLocalStorageData(colorMode) === 'light'));


  // Global state values and functions
  const value = {
    loading,
    setLoading,
    userData,
    setUserData,
    appListData,
    setAppListData,
    selectedApp,
    setSelectedApp,
    isLightColor,
    setIsLightColor,
    refreshMainTable,
    setRefreshMainTable
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

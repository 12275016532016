import { BlockUserModel, BlockUserResponse, CriteriaModel } from '../../friday/models/authModel';
import GenericTable from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blockUserDelete, blockUserGetList } from '../../friday/services/blockUserService';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const gridColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'userId',
    header: 'UserId',
  },
  {
    accessorKey: 'ipAdress',
    header: 'ipAdress',
  },
  {
    accessorKey: 'reason',
    header: 'Reason',
  },
  {
    accessorKey: 'isPermanent',
    header: 'IsPermanent',
  },
  {
    accessorKey: 'blockedUntil',
    header: 'BlockedUntil',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export const blockUserGetListEvent = async (data: CriteriaModel) => {
  const response = await blockUserGetList(data); 
  return response;
};

export default function BlockUserTable() {
  return (
    <ThemeProvider theme={theme}>
    <GenericTable<BlockUserModel, BlockUserResponse>
      getServiceCall={blockUserGetListEvent} // No changes here!
      columns={gridColumns}
      isDeletePopup={true}
      deleteServiceFunction={blockUserDelete}
    />
    </ThemeProvider>
  );
};

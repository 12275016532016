// ROUTE PATH
export const ROUTE_PATH_ADMIN = '/admin';
export const ROUTE_PATH_ADMIN_APPS = '/admin/apps';
export const ROUTE_PATH_ADMIN_USERS = '/admin/users';
export const ROUTE_PATH_ADMIN_EXTERNALAUTHS = '/admin/externalauths';
export const ROUTE_PATH_ADMIN_PURCHASEPACKAGES = '/admin/purchase-packages';
export const ROUTE_PATH_ADMIN_BLOCKUSERS = '/admin/block-users';
export const ROUTE_PATH_AUTH_SIGNIN = '/auth/sign-in';
export const ROUTE_PATH_ADMIN_DEFAULT = '/admin/default'

export const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://friday-api.trihay.net/api' 
  : 'https://localhost:7170/api';

export const GUIDEMPTY = '00000000-0000-0000-0000-000000000000';
export const APP_ID = '6F205BB0-2276-4A0D-BB16-75B9AFDEF8AD'
export const APP_NAME = 'Friday API'
import { Icon } from '@chakra-ui/react';
import {
  MdApps,
  MdGroup,
  MdSecurity,
  MdLocalShipping,
  MdBlock,
  MdLock,
} from 'react-icons/md';

// Auth Imports
import SignInCentered from './views/auth/signIn';
import Apps from './views/admin/apps';
import Users from './friday/components/Users';
import ExternalAuths from './friday/components/ExternalAuths';
import PurchasePackage from './friday/components/PurchasePackage';
import BlockUser from './friday/components/BlockUser';

const routes = [
  /*   {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  }, */
  {
    name: 'Apps',
    layout: '/admin',
    path: '/apps',
    icon: (
      <Icon
        as={MdApps}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <Apps />,
    secondary: true,
  },
  {
    name: 'Users',
    layout: '/admin',
    icon: <Icon as={MdGroup} width="20px" height="20px" color="inherit" />,
    path: '/users',
    component: <Users />,
  },
  {
    name: 'External Auths',
    layout: '/admin',
    icon: <Icon as={MdSecurity} width="20px" height="20px" color="inherit" />,
    path: '/externalauths',
    component: <ExternalAuths />,
  },
  {
    name: 'P. Packages',
    layout: '/admin',
    icon: <Icon as={MdLocalShipping} width="20px" height="20px" color="inherit" />,
    path: '/purchase-packages',
    component: <PurchasePackage />,
  },
  {
    name: 'Block Users',
    layout: '/admin',
    icon: <Icon as={MdBlock} width="20px" height="20px" color="inherit" />,
    path: '/block-users',
    component: <BlockUser />,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
];

export default routes;

import { CriteriaModel,PurchasePackageModel, PurchasePackageResponse } from '../../friday/models/authModel';
import GenericTable, { GenericTableHandle } from './GenericTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { purchasePackageDelete, purchasePackageGetList } from '../../friday/services/purchasePackageService';
import { useEffect, useRef } from 'react';

const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });

const gridColumns = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'credits',
    header: 'Credits',
  },
  {
    accessorKey: 'isForAdd',
    header: 'IsForAdd',
  },
  {
    accessorKey: 'createDate',
    header: 'Created Date',
  }
];

export const purchasePackageGetListEvent = async (data: CriteriaModel) => {
  const response = await purchasePackageGetList(data); 
  return response;
};

export default function PurchasePackageTable () {
  return (
    <ThemeProvider theme={theme}>
    <GenericTable<PurchasePackageModel, PurchasePackageResponse>
      getServiceCall={purchasePackageGetListEvent} // No changes here!
      columns={gridColumns}
      isDeletePopup={true}
      deleteServiceFunction={purchasePackageDelete}
    />
    </ThemeProvider>
  );
};

interface FormField {
    name: string;
    label: string;
    type: 'text' | 'select' | 'number';
    options?: string[];
    value?: string | number;
  }
  
interface DynamicFormPopupProps {
    fields?: FormField[];
    isOpen: boolean;
    title: string;
    bodyText?: string;
    onSave?: (formData: { [key: string]: any }) => void;
    onSaveEvent?: () => any;
    onCancel: () => void;
}

interface LoginRequest {
    email: string;
    password: string;
}

interface AccessTokenModel {
    token: string;
    expiration: string;
}

interface FilterModel {
    field: string;
    value: any;
    operator: string; // E.g., "equals", "contains", etc.
}

interface SortModel {
    field: string;
    type: SortType;
}
  
interface CriteriaModel {
    page: number;
    pageSize: number;
    filters?: FilterModel[]; // Nullable array of FilterModel
    sorts?: SortModel; // Optional SortModel
}

// Enum for Sort Type
enum SortType {
    Ascending = 1,
    Descending = 2,
}

// MessageModel interface
interface MessageModel {
    code?: string; // Optional string
    text?: string; // Optional string
  }
  
  // AppModel interface
interface AppModel {
    id?: string; // Optional UUID string
    createDate?: string; // Optional ISO date-time string
    updateDate?: string; // Optional ISO date-time string
    name?: string; // Optional string
    description?: string; // Optional string
    url?: string; // Optional string
}

enum Status {
    ACTIVE = 1,
    PASSIVE = 2,
    DELETED = 3,
    NOTCONFIRMED = 4
}

interface UserModel {
    id: string | null;         // UUID as string, nullable
    appId: string | null;      // UUID as string, nullable
    createDate: string | null; // ISO 8601 date-time string, nullable
    updateDate: string | null; // ISO 8601 date-time string, nullable
    firstName: string | null;  // Nullable string
    lastName: string | null;   // Nullable string
    email: string | null;      // Nullable string
    status: Status | null;     // Status enum, nullable
}

// BaseResponse interface
interface BaseListResponse<T> {
    isSuccess: boolean;
    messages?: MessageModel[];
    data?: T[];
    totalCount?: number;
}

interface BaseResponse<T> {
    isSuccess: boolean;
    messages?: MessageModel[];
    data?: T;
    totalCount?: number;
}
interface BaseRequest<T> {
    data?: T;
}
interface BaseListRequest<T> {
    data?: T[];
}

interface SelectedAppResponseModel {
    token: string;
    expiration: string;
    appId: string;
    appName: string;
}

interface CreateNewAppModel {
    name?: string;
    description?: string; 
    url?: string;
}

enum AuthType {
    Mail = 1,
    Google = 2,
    Apple = 3
}
const AuthTypeEnumNames = {
    [AuthType.Mail]: 'Mail',
    [AuthType.Google]: 'Google',
    [AuthType.Apple]: 'Apple'
};
  
enum DeviceType {
    Web = 1,
    Android = 2,
    IOS = 3,
    None = 4
}
const DeviceTypeEnumNames = {
    [DeviceType.Web]: 'Web',
    [DeviceType.Android]: 'Android',
    [DeviceType.IOS]: 'IOS',
    [DeviceType.None]: 'None'
};
  
interface ExternalAuthKeyModel {
    id: string | null;
    appId: string | null;
    createDate: string | null;
    updateDate: string | null;
    authType: AuthType;
    authTypeValue?: string; //ARAYUZ ICIN
    deviceType: DeviceType;
    deviceTypeValue?: string; //ARAYUZ ICIN
    clientId: string | null;
    secret: string | null;
}

interface PurchasePackageModel {
    id: string | null;
    appId: string | null;
    createDate: string | null;
    updateDate: string | null;
    isForAdd: boolean; //ARAYUZ ICIN
    credits: number | null;
    name: string | null;
}

interface BlockUserModel {
    id: string | null;
    appId: string | null;
    createDate: string | null;
    updateDate: string | null;
    userId: string;
    iPAdress: string | null;
    reason: string | null;
    isPermanent: boolean | null;
    blockedUntil: string | null;
}
  
type ExternalAuthKeyResponse = BaseListResponse<ExternalAuthKeyModel>;
type AppResponse = BaseListResponse<AppModel>;
type UserResponse = BaseListResponse<UserModel>;
type ExternalAuthKeyRequest = BaseListRequest<ExternalAuthKeyModel>;
type CreateNewAppRequest = BaseListRequest<CreateNewAppModel>;
type CreateNewAppResponse = BaseResponse<CreateNewAppModel>;
type SelectedAppResponse = BaseResponse<SelectedAppResponseModel>;
type LoginResponse = BaseResponse<AccessTokenModel>;
type PurchasePackageResponse = BaseResponse<PurchasePackageModel>;
type PurchasePackageRequest = BaseListRequest<PurchasePackageModel>;
type BlockUserRequest = BaseListRequest<BlockUserModel>;
type BlockUserResponse = BaseResponse<BlockUserModel>;

export { LoginRequest, 
    LoginResponse,
    FilterModel,
    SortModel,
    CriteriaModel,
    SortType,
    MessageModel,
    AppModel,
    AppResponse,
    BaseResponse,
    SelectedAppResponseModel,
    SelectedAppResponse,
    CreateNewAppModel,
    CreateNewAppResponse,
    CreateNewAppRequest,
    DynamicFormPopupProps,
    FormField,
    UserModel,
    Status,
    UserResponse,
    ExternalAuthKeyResponse,
    ExternalAuthKeyRequest,
    ExternalAuthKeyModel,
    AuthType,
    AuthTypeEnumNames,
    DeviceType,
    DeviceTypeEnumNames,
    PurchasePackageModel,
    PurchasePackageResponse,
    PurchasePackageRequest,
    BlockUserModel,
    BlockUserRequest,
    BlockUserResponse };